/* -----------------------------------------------------------------------------------------------------------------------------------*/
/* Default Styling - Use for Background, Text, etc. */
.default-background{
  margin: 0;
  padding: 0;
  background-color: #3498db;
}
.default-text {
  font-size: 30px;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}






/* -----------------------------------------------------------------------------------------------------------------------------------*/

body , html {
  margin: 0;
  padding: 0;
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
  font-style: oblique;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* -----------------------------------------------------------------------------------------------------------------------------------*/
/* Navigation Bar Styling */
nav {
  background-color: #333;
  height: 75px; /* Adjust the height as needed */
  display: flex;
  align-items: center; /* Vertically center the content within the navbar */
  justify-content: center;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}


.nav-list a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: x-large;
  margin-left: 20px;

  transition: color 0.3s;
}

.nav-list a:hover {
  color: #ffc107;
}

/* -----------------------------------------------------------------------------------------------------------------------------------*/



